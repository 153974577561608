import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
import router from "@/router";

let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}

if ("/partner/users/".includes(router.history.current.path)) {
  ApiService.setHeader("x-api-key", store.getters.apiTokenPartner);
} else {
  ApiService.setHeader("x-api-key", store.getters.apiToken);
}
if (store.getters.apiTokenPartner) {
  ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
}

class Users {
  get(id) {
    return ApiService.get(url, "users/" + id);
  }
  getAll(params, optionalHeaders = {}) {
    if (!store.getters.apiToken && process.env.VUE_APP_VERSION < 2) {
      return;
    }
    let options = new URLSearchParams(params).toString();
    return ApiService.get(url, "users?" + options, optionalHeaders);
  }
  getAllPartnerUsers(params, optionalHeaders = {}) {
    if (store.getters.apiTokenPartner) {
      ApiService.setHeader("x-api-key", store.getters.apiTokenPartner);
    }
    if (!params.page) {
      params.page = 1;
    }
    var options = "?page=" + params.page + "&perPage=" + params.size;
    let returnData = ApiService.get(url, "users" + options, optionalHeaders);
    ApiService.setHeader("x-api-key", store.getters.apiToken);
    if (store.getters.apiTokenPartner) {
      ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
    }
    return returnData;
  }
  store(data) {
    ApiService.setHeader("Content-Type", "multipart/form-data");
    return ApiService.post(url + "/users", data);
  }
  update(userId, data) {
    ApiService.setHeader("Content-Type", "multipart/form-data");
    return ApiService.put(url + "/users/" + userId, data);
  }
  delete(id) {
    return ApiService.delete(url + "/users/" + id);
  }
  getMainContact() {
    return ApiService.get(url, "main_contact");
  }
  getPartnerContact() {
    return ApiService.get(url, "partner_contact");
  }
}
export default new Users();
