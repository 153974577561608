import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";

const url = process.env.VUE_APP_API_ADMIN;
const urlTenant = process.env.VUE_APP_API_TENANT;

ApiService.setHeader();

class Languages {
  getAll() {
    if (store.getters.userType === "admin") {
      return ApiService.get(url, "languages");
    }
    return ApiService.get(urlTenant, "languages");
  }
}
export default new Languages();
