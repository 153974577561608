<template>
  <div class="offset-md-2 col-md-8 card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        id="kt_wizard_v1"
        class="wizard wizard-1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div class="wizard-steps p-8 p-lg-10">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <i class="wizard-icon fal fa-list"></i>
                <h3 class="wizard-title">1. {{ $t("users.baseData") }}</h3>
              </div>
              <i class="wizard-arrow fal fa-chevron-right"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon fal fa-file-image"></i>
                <h3 class="wizard-title">
                  2. {{ $t("users.profilePicture") }}
                </h3>
              </div>
              <i class="wizard-arrow fal fa-chevron-right"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon fal fa-user-gear"></i>
                <h3 class="wizard-title">3. {{ $t("users.profile") }}</h3>
              </div>
              <i class="wizard-arrow fal fa-chevron-right"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon fal fa-check"></i>
                <h3 class="wizard-title">
                  4. {{ $t("users.reviewAndSubmit") }}
                </h3>
              </div>
              <i class="wizard-arrow last fal fa-chevron-right"></i>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->
        <v-progress-linear v-if="isBusy" indeterminate color="primary" />
        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-12">
            <!--begin: Wizard Form-->
            <form id="kt_form" class="form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h3 class="mb-10 font-weight-bold text-dark">
                  {{ $t("users.baseData") }}:
                </h3>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("users.salutation") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <b-form-input
                      v-model="user.core_data.salutation"
                      class="form-control"
                      :placeholder="$t('users.enterSalutation')"
                    ></b-form-input>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("users.title") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <b-form-input
                      v-model="user.core_data.title"
                      class="form-control"
                      :placeholder="$t('users.enterTitle')"
                    ></b-form-input>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("users.firstName") }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <b-form-input
                      id="userFirstName"
                      v-model="user.core_data.firstname"
                      :placeholder="$t('users.enterFirstName')"
                      aria-describedby="userFirstName-help userFirstName-feedback"
                      trim
                      class="form-control"
                      :class="
                        !$v.user.core_data.firstname.required
                          ? 'is-invalid'
                          : 'is-valid' && !$v.user.core_data.firstname.minLength
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validateFields(1)"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.user.core_data.firstname.required"
                      id="userLastName-feedback"
                    >
                      {{ $t("validation.required.firstname") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.user.core_data.firstname.minLength"
                      id="userLastName-feedback"
                    >
                      {{ $t("validation.minChars", { chars: 3 }) }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("users.lastName") }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <b-form-input
                      id="userLastName"
                      v-model="user.core_data.lastname"
                      :placeholder="$t('users.enterLastName')"
                      aria-describedby="userLastName-help userLastName-feedback"
                      trim
                      class="form-control"
                      :class="
                        !$v.user.core_data.lastname.required
                          ? 'is-invalid'
                          : 'is-valid' && !$v.user.core_data.lastname.minLength
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validateFields(1)"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.user.core_data.lastname.required"
                      id="userLastName-feedback"
                    >
                      {{ $t("validation.required.lastname") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.user.core_data.lastname.minLength"
                      id="userLastName-feedback"
                    >
                      {{ $t("validation.minChars", { chars: 3 }) }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("users.email") }} <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <b-form-input
                      id="userEmail"
                      v-model="user.email"
                      :placeholder="$t('users.enterEmail')"
                      aria-describedby="userEmail-help userEmail-feedback"
                      trim
                      class="form-control"
                      :class="
                        !$v.user.email.required
                          ? 'is-invalid'
                          : 'is-valid' && !$v.user.email.email
                          ? 'is-invalid'
                          : 'is-valid' && !$v.user.email.isUnique
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validateFields(1)"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.user.email.required"
                      id="userEmail-feedback"
                    >
                      {{ $t("validation.required.email") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.user.email.email"
                      id="userEmail-feedback"
                    >
                      {{ $t("validation.email.email") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.user.email.isUnique"
                      id="userEmail-feedback"
                    >
                      {{ $t("validation.unique.email") }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("general.active") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <span class="switch switch-sm">
                      <label>
                        <input v-model="user.active" type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  {{ $t("users.profilePicture") }}:
                </h4>
                <div class="user-avatar-upload">
                  <div
                    v-show="$refs.upload && $refs.upload.dropActive"
                    class="drop-active"
                  >
                    <h3>{{ $t("users.dropFilesToUpload") }}</h3>
                  </div>
                  <div v-show="!edit" class="avatar-upload">
                    <div class="text-center p-2">
                      <label for="avatar">
                        <img
                          :src="
                            files.length
                              ? files[0].url
                              : 'media/users/blank.png'
                          "
                          class="rounded-circle"
                        />
                        <h4 class="pt-2">
                          {{ $t("users.dropFilesAnywhereToUpload") }}
                        </h4>
                      </label>
                    </div>
                    <div class="text-center p-2">
                      <file-upload
                        ref="upload"
                        v-model="files"
                        extensions="gif,jpg,jpeg,png,webp"
                        accept="image/png,image/gif,image/jpeg,image/webp"
                        name="avatar"
                        class="btn btn-primary"
                        :drop="!edit"
                        @input-filter="inputFilter"
                        @input-file="inputFile"
                      >
                        {{ $t("users.uploadProfilePicture") }}
                      </file-upload>
                      <button
                        v-show="files.length"
                        type="button"
                        class="btn btn-secondary m-2"
                        @click.prevent="remove"
                      >
                        {{ $t("users.removeProfilePicture") }}
                      </button>
                    </div>
                  </div>

                  <div v-show="files.length && edit" class="avatar-edit">
                    <div v-if="files.length" class="avatar-edit-image">
                      <img
                        v-if="files[0].url"
                        ref="editImage"
                        :src="files[0].url"
                      />
                    </div>
                    <div class="text-center p-4">
                      <button
                        type="button"
                        class="btn btn-secondary m-2"
                        @click.prevent="$refs.upload.clear"
                      >
                        {{ $t("general.cancel") }}
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary m-2"
                        @click.prevent="editSave"
                      >
                        {{ $t("users.cropProfilePicture") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  {{ $t("users.profile") }}:
                </h4>

                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("users.password") }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <b-form-input
                      id="userPassword"
                      v-model="password"
                      type="password"
                      :placeholder="$t('users.enterPassword')"
                      aria-describedby="userPassword-help userPassword-feedback"
                      trim
                      class="form-control"
                      :class="passwordValidationClass"
                      @input="validateFields(3)"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.password.required"
                      id="userPassword-feedback"
                    >
                      {{ $t("validation.required.password") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.password.minLength"
                      id="userPassword-feedback"
                    >
                      {{ $t("validation.minChars", { chars: 8 }) }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback v-if="!$v.password.alphaNum">
                      {{ $t("validation.alphaNum") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback v-if="!$v.password.upLowCase">
                      {{ $t("validation.upLowCase") }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("users.passwordConfirmation") }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <b-form-input
                      id="userPasswordConfirmation"
                      v-model="passwordConfirmation"
                      type="password"
                      :placeholder="$t('users.enterPasswordConfirmation')"
                      aria-describedby="userPasswordConfirmation-help userPasswordConfirmation-feedback"
                      trim
                      class="form-control"
                      :class="
                        !$v.passwordConfirmation.sameAs
                          ? 'is-invalid'
                          : 'is-valid' && !$v.passwordConfirmation.required
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validateFields(3)"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.passwordConfirmation.sameAs"
                      id="userPasswordConfirmation-feedback"
                    >
                      {{ $t("validation.samePassword") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="
                        !$v.passwordConfirmation.required &&
                        $v.passwordConfirmation.sameAs
                      "
                      id="userPasswordConfirmation-feedback"
                    >
                      {{ $t("validation.samePassword") }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("users.role") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <v-select
                      v-model="user.role_id"
                      class="form-control"
                      :items="roleOptions"
                      item-text="role"
                      item-value="id"
                      :label="$t('users.selectRole')"
                      multiple
                      single-line
                      :menu-props="{ offsetY: true }"
                      @change="roleChanged"
                    ></v-select>

                    <b-form-text>{{ $t("users.selectRole") }}</b-form-text>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("users.language") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <v-select
                      v-model="user.language_id"
                      class="form-control"
                      :items="languageOptions"
                      item-text="label"
                      item-value="id"
                      :menu-props="{ offsetY: true }"
                      @change="languageChanged"
                    />
                    <b-form-text>{{ $t("users.selectLanguage") }}</b-form-text>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 4-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  {{ $t("users.reviewAndSubmit") }}:
                </h4>
                <div class="row">
                  <div class="col-md-3">
                    <div class="symbol symbol-150 mr-5 text-center">
                      <img
                        class="symbol-label"
                        :src="
                          files.length ? files[0].url : 'media/users/blank.png'
                        "
                      />
                      <br />
                      <b-badge
                        v-if="user.active"
                        variant="success"
                        class="text-uppercase"
                      >
                        {{ $t("general.active") }}
                      </b-badge>
                      <b-badge
                        v-else
                        variant="secondary"
                        class="text-uppercase"
                      >
                        {{ $t("general.inactive") }}
                      </b-badge>
                    </div>
                  </div>
                  <div
                    class="col-md-9 font-size-lg text-left"
                    style="line-height: 2"
                  >
                    <strong>{{ $t("users.salutation") }}</strong
                    >: {{ user.core_data.salutation }} <br />
                    <strong>{{ $t("users.title") }}</strong
                    >: {{ user.core_data.title }} <br />
                    <strong>{{ $t("users.firstName") }}</strong
                    >: {{ user.core_data.firstname }} <br />
                    <strong>{{ $t("users.lastName") }}</strong
                    >: {{ user.core_data.lastname }} <br />
                    <strong>{{ $t("users.email") }}</strong
                    >: {{ user.email }} <br />
                    <strong>{{ $t("users.language") }}</strong
                    >: {{ selectedLanguageLabel }}<br />
                    <strong>{{ $t("users.role") }}</strong
                    >:
                    <b-badge
                      v-for="(item, i) in selectedRoleLabel"
                      :key="i"
                      :value="item"
                      variant="success"
                      class="text-uppercase m-1"
                    >
                      {{ item }}
                    </b-badge>
                    <br />
                  </div>
                </div>
                <!--
                <div class="row mt-8">
                  <div class="col-md-12">
                    <b-form-group>
                      <b-form-checkbox
                          v-model="sendWelcomeEmail"
                          value="1"
                          switch

                      >
                        {{ $t("users.sendWelcomeEmail") }}
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                </div>
                -->
              </div>
              <!--end: Wizard Step 4-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <button
                    :class="
                      wizardNavigationDisabled ? 'btn-secondary' : 'btn-primary'
                    "
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    {{ $t("general.previous") }}
                  </button>
                </div>
                <div>
                  <button
                    class="btn font-weight-bold text-uppercase px-9 py-4"
                    :class="
                      wizardNavigationDisabled ? 'btn-secondary' : 'btn-primary'
                    "
                    data-wizard-type="action-next"
                  >
                    {{ $t("general.next") }}
                  </button>
                  <button
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                    @click="submit"
                  >
                    {{ $t("users.userCreate") }}
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
.user-avatar-upload .avatar-upload .rounded-circle {
  width: 200px;
  height: 200px;
}
.user-avatar-upload .text-center .btn {
  margin: 0 0.5rem;
}
.user-avatar-upload .avatar-edit-image {
  max-width: 100%;
}
.user-avatar-upload .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.user-avatar-upload .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.cropper-container {
  max-width: calc(100% + 30px);
}

.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}

.col-md-9 {
  .v-select.form-control {
    .v-input__slot {
      font-size: 1rem;
      padding-top: 8px;
      padding-left: 1.42rem;
    }
  }
}
</style>

<script>
import KTWizard from "@/assets/js/components/wizard";
import Languages from "@/components/General/Languages/languages";
import Users from "@/components/Projects/Settings/Users/users";
import Roles from "@/components/Admins/Roles/roles";
import FileUpload from "vue-upload-component";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";

import {
  email,
  required,
  minLength,
  sameAs,
  helpers
} from "vuelidate/lib/validators";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

const alphaNum = helpers.regex(
  "alphaNum",
  /^(?=.*[a-zA-Z])(?=.*[0-9])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]+$/
);
const upLowCase = helpers.regex(
  "upLowCase",
  /^(?=(?:.*[A-Z]){1})(?=(?:.*[a-z]){1}).*/
);

export default {
  validations: {
    user: {
      core_data: {
        firstname: { required, minLength: minLength(3) },
        lastname: { required, minLength: minLength(3) }
      },
      email: {
        email,
        required,
        isUnique(value) {
          return !this.currentUsers.includes(value);
        }
      }
    },
    password: { required, minLength: minLength(8), alphaNum, upLowCase },
    passwordConfirmation: {
      required,
      sameAs: sameAs("password")
    }
  },
  name: "ProjectUsersCreate",
  components: {
    FileUpload
  },
  data() {
    return {
      isBusy: false,
      files: [],
      edit: false,
      cropper: false,
      wizardNavigationDisabled: true,
      selectedLanguageLabel: "",
      selectedRoleLabel: "",
      languageOptions: [],
      roleOptions: [],
      sendWelcomeEmail: false,
      password: "",
      passwordConfirmation: "",
      user: {
        email: "",
        active: false,
        language_id: "",
        role_id: [],
        core_data: {
          salutation: "",
          title: "",
          firstname: "",
          lastname: ""
        },
        profile_picture: ""
      },
      currentUsers: []
    };
  },
  computed: {
    passwordValidationClass() {
      if (
        !this.$v.password.required ||
        !this.$v.password.minLength ||
        !this.$v.password.alphaNum ||
        !this.$v.password.upLowCase
      ) {
        return "is-invalid";
      }
      return "is-valid";
    }
  },
  watch: {
    edit(value) {
      if (value) {
        this.wizardNavigationDisabled = true;

        this.$nextTick().then(() => {
          if (!this.$refs.editImage) {
            return;
          }

          var cropper_opts = {
            aspectRatio: 1 / 1,
            viewMode: 1,
            crop: function () {}, //to show the crop box manually
            minCanvasWidth: 200,
            minCanvasHeight: 200,
            minCropBoxWidth: 100,
            minCropBoxHeight: 100,
            minContainerWidth: 200, //decides the size of image
            minContainerHeight: 200, //decides the size of image
            autoCropArea: 1,
            modal: true, // Show the black modal
            guides: true, // Show the dashed lines for guiding
            center: true, // Show the center indicator for guiding
            highlight: true, // Show the white modal to highlight the crop box
            background: true, // Show the grid background,
            scalable: true,
            rotatable: true,
            checkOrientation: true,
            cropBoxResizable: true,
            dragMode: "move"
          };

          let cropper = new Cropper(this.$refs.editImage, cropper_opts);
          this.cropper = cropper;
        });
      } else {
        this.wizardNavigationDisabled = false;

        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = false;
        }
      }
    }
  },
  mounted() {
    this.getLanguages();
    this.getUsers();
    this.getRoles();
    let wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1,
      clickableSteps: false
    });
    wizard.on("change", wizardObj => {
      if (this.wizardNavigationDisabled) {
        wizardObj.stop();
      }
    });
    wizard.on("changed", wizardObj => {
      if (wizardObj.currentStep === 3) {
        this.validateFields(3);
      }
    });

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.tenantUserAdministration"),
        route: ""
      },
      {
        title: this.$t("general.overview"),
        route: "/tenant/users/"
      },
      {
        title: this.$t("users.userCreate"),
        route: "/tenant/users/add"
      }
    ]);
  },
  methods: {
    editSave() {
      this.edit = false;
      let oldFile = this.files[0];
      let binStr = atob(
        this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(",")[1]
      );
      let arr = new Uint8Array(binStr.length);
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type });
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true
      });
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.$nextTick().then(() => {
          this.edit = true;
        });
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.$swal.fire({
            icon: "error",
            title: this.$t("users.notAPicture")
          });
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
    remove() {
      this.files = [];
    },

    getLanguages() {
      Languages.getAll()
        .then(response => {
          let self = this;
          response.data.data.forEach(async function (data) {
            self.user.language_id = data.id;
            self.selectedLanguageLabel = data.label;
          });
          this.languageOptions = response.data.data;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    getRoles() {
      let params = {
        page: 1,
        size: 100
      };
      Roles.getAll(params)
        .then(response => {
          let self = this;
          self.user.role_id = [];
          response.data.data.forEach(async function (data) {
            let tmpObj = {
              id: data.id,
              role: data.name
            };
            self.roleOptions.push(tmpObj);
          });
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    getUsers() {
      let params = {
        page: 1,
        size: 100
      };
      Users.getAll(params)
        .then(response => {
          let self = this;
          response.data.data.forEach(async function (data) {
            self.currentUsers.push(data.email);
          });
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    languageChanged(e) {
      const language = this.languageOptions.find(l => l.id === e);
      if (language) {
        this.selectedLanguageLabel = language.label;
      }
    },
    roleChanged(e) {
      this.selectedRoleLabel = [];
      let roleOptions = Object.assign([], this.roleOptions);
      let selectedRoles = Object.assign([], e);
      selectedRoles.forEach(selectedRoleId => {
        roleOptions.forEach(roleData => {
          let data = Object.assign([], roleData);
          if (data.id === selectedRoleId) {
            this.selectedRoleLabel.push(data.role);
          }
        });
      });
    },

    validateFields(step) {
      this.wizardNavigationDisabled = true;
      this.$v.$touch();
      switch (step) {
        case 1:
          if (
            !this.$v.user.email.$invalid &&
            !this.$v.user.core_data.firstname.$invalid &&
            !this.$v.user.core_data.lastname.$invalid
          ) {
            this.wizardNavigationDisabled = false;
          }
          break;
        case 3:
          if (
            !this.$v.password.$invalid &&
            !this.$v.passwordConfirmation.$invalid
          ) {
            this.wizardNavigationDisabled = false;
          }
          break;
      }
    },
    submit: function (e) {
      e.preventDefault();
      this.isBusy = true;

      let data = {
        name: this.user.core_data.firstname,
        email: this.user.email,
        core_data: this.user.core_data,
        active: this.user.active ? 1 : 0,
        language_id: this.user.language_id,
        password: this.password
        // send_welcome_email: (this.sendWelcomeEmail) ? 1 : 0,
      };

      let form = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        form.append(key, value);
      });

      this.user.role_id = Object.assign([], this.user.role_id);
      if (this.user.role_id.length) {
        this.user.role_id.forEach(data => {
          form.append("role_id[]", data);
        });
      }

      if (this.files.length) {
        form.append("profile_picture", this.files[0].file);
      }

      Users.store(data)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("users.userCreated")
          });
          this.$router.push({ name: "projectSettingsUsers" });
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>
