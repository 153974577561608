import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";

ApiService.setHeader("x-api-key", store.getters.apiToken);
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}

class Roles {
  get(id) {
    return ApiService.get(url, "roles/" + id);
  }
  getAll(params) {
    var options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "roles" + options);
  }
  store(data) {
    return ApiService.post(url + "/roles", data);
  }
  update(roleId, data) {
    return ApiService.put(url + "/roles/" + roleId, data);
  }
  delete(id) {
    return ApiService.delete(url + "/roles/" + id);
  }
}
export default new Roles();
